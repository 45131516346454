<template>
    <v-container class="userLayout">
        <v-layout column>
            <div class="text-center" v-if="!userInfo">
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card v-if="userInfo">
                    <v-card-title class="text-h5">
                        <router-link to="/admin/users" class="mr-5"> <v-icon>fas fa-angle-left</v-icon></router-link> Edit User
                    </v-card-title>
                    <v-card-text>
                        <div class="mb-4">
                            <v-avatar
                            color="primary"
                            size="56"
                            ><span class="white--text text-h5">{{getInitials(userInfo.name)}}</span></v-avatar>
                        </div>
                        <v-text-field
                            v-model="userInfo.name"
                            :rules="nameRules"
                            label="Name"></v-text-field>
                        <v-text-field
                            v-model="userInfo.email"
                            :rules="emailRules"
                            label="Email Address"></v-text-field>
                        <v-select
                                v-model="userInfo.status"
                                :items="[true,false]"
                                label="Enabled"
                                data-vv-name="Enabled"
                                required
                            >
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-title
                                        :id="attrs['aria-labelledby']"
                                        v-text="item"
                                        class="text-dark"
                                        >
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                        </v-select>
                        <v-select
                            v-model="userInfo.role"
                            :items="['user','admin']"
                            label="Role"
                            data-vv-name="Role"
                            required
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                    <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                    class="text-dark"
                                    >
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-text-field
                            v-model="newPass"
                            :rules="passwordRules"
                            :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass2 = !showPass2"
                            :type="showPass2 ? 'text' : 'password'"
                            label="New Password"></v-text-field>
                        <div>
                            <p style="margin-bottom:5px">Password policy</p>
                            <ul>
                                <li :class="r1?'':'text-red'">At least one upper case English letter</li>
                                <li :class="r2?'':'text-red'">At least one lower case English letter</li>
                                <li :class="r3?'':'text-red'">At least one digit</li>
                                <li :class="r4?'':'text-red'">At least one special character</li>
                                <li :class="r5?'':'text-red'">Minimum eight in length</li>
                            </ul>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :loading="loading" @click.native="update">
                            <v-icon left dark>fas fa-check</v-icon>
                            Save Changes
                        </v-btn>
                        <v-btn color="red" dark @click.native="delDial = true">
                            <v-icon left dark>fas fa-times</v-icon>
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-layout>
        <v-snackbar v-model="error" :multi-line="true" color="red accent-2">
            {{ errorTxt }}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="error = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="success" :multi-line="true" color="green">
            Account information updated successfully
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="success = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="accountCreated" :multi-line="true" color="green">
            Account registered successfully
            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="accountCreated = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog
            v-model="delDial"
            persistent
            max-width="550"
        >
            <v-card>
                <v-card-title class="text-h5" v-if="!deleting">
                    Are you sure toy want to delete thi account?
                </v-card-title>
                <v-card-title class="text-h5" v-else>
                    Deleting 
                    <v-progress-circular
                        indeterminate
                        color="red"
                        small
                        class="ml-4"
                    ></v-progress-circular>
                </v-card-title>
                <v-card-text v-if="!deleting">
                    This action is irreversible, are you sure you want to delete account
                </v-card-text>
                <v-card-actions v-if="!deleting">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="delDial = false"
                    >
                        No
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteAccount"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    watch:{
        newPass:function(){
            if(!this.newPass){
                this.r1=true;
                this.r2=true;
                this.r3=true;
                this.r4=true;
                this.r5=true;
            }else{
                this.r1 = /^(?=.*?[A-Z])/.test(this.newPass)
                this.r2 = /^(?=.*?[a-z])/.test(this.newPass)
                this.r3 = /^(?=.*?[0-9])/.test(this.newPass)
                this.r4 = /^(?=.*?[#?!@$%^&*-])/.test(this.newPass)
                this.r5 = /.{8,}$/.test(this.newPass)
            }
        }
    },
    data(){
        return{
            r1:true,
            r2:true,
            r3:true,
            r4:true,
            r5:true,
            email:"",
            deleting:false,
            delDial:false,
            success:false,
            error:false,
            errorTxt:"",
            userInfo: null,
            newPass:"",
            showPass1:false,
            showPass2:false,
            loading:false,
            valid: true,
            accountCreated: false,
            oldpassRules: [
                v => !!v || 'Old Password is required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !/.{1,}$/.test(`${v}`) || /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'The password does not meet the password policy',
                //v => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'E-mail must be valid',
            ]
        }
    },
    async mounted(){
        if(this.$route.query && this.$route.query.created){
            this.accountCreated = true;
        }
        if(this.$route.params.id == this.$store.getters.getUser._id){
            this.$router.push('/admin/profile')
            return;
        }
        try{
            let res = await this.$http.get(`${process.env.VUE_APP_API_ROOT}/users/${this.$route.params.id}`);
            this.userInfo = res.data.data;
        }
        catch(e){
            this.error = true;
            this.errorTxt = "Invalid User ID, You are been redirected to users page"
            setTimeout(this.sendToUsers,500);
        }
    },
    methods:{
        async deleteAccount(){
            const id = this.$route.params.id;
            this.deleting = true;
            this.$http.delete(`${process.env.VUE_APP_API_ROOT}/users/${id}`)
            .then(this.handleDel)
            .catch(this.handleErr)
        },
        handleDel(){
            this.deleting = false;
            this.delDial = false;
            this.$router.push('/admin/users?accountDeleted=true')
        },
        sendToUsers(){
            this.$router.push('/admin/users')
        },
        getInitials(name){
            if(name) {
                let array = name.split(" ");
                if(array.length == 1){
                    return array[0].charAt(0).toUpperCase();
                }
                else{
                    return array[0].charAt(0).toUpperCase() + array[ array.length -1 ].charAt(0).toUpperCase();
                }
            }
            return "NaN";
        },
        update(){
            this.loading = true;
            this.error = false;
            this.success = false;
            if(this.$refs.form.validate()){
                let sendData = {
                    id:this.$route.params.id,
                    name:this.userInfo.name,
                    email:this.userInfo.email,
                    role:this.userInfo.role,
                    status:this.userInfo.status,
                }
                if(this.newPass) sendData.password = this.newPass;
                this.$http.put(`${process.env.VUE_APP_API_ROOT}/auth/profile`,sendData)
                .then(this.handleRes)
                .catch(this.handleErr)
            }
            else{
                this.loading = false;
                this.error = true;
                this.errorTxt = "Please check all the fields"
            }
        },
        async handleRes(){
            this.loading = false;
            this.success = true;
            this.newPass = ""
            this.$refs.form.resetValidation()
        },
        handleErr(e){
            console.log(e)
            this.loading = false;
            this.error = true;
            if(e.response.data.message.includes('E11000') && e.response.data.message.includes('email'))
                this.errorTxt = "Email address already exists"
            else this.errorTxt = e.response.data.message
        }
    }
}
</script>

<style>
.userLayout{
    max-width: 500px;
}
.text-red{
    color:red;
}
</style>